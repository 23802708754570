import React from 'react';
import {useStyles} from './style_resume';
import {Typography, Box, Grid} from '@material-ui/core'
import Typed from 'react-typed';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './resume.style.css';
import cert from './certificate.PNG';
import lander from './lander.PNG';
import Slide from '@material-ui/core/Slide';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ShowVolunteer from '../aboutme/showVolunteer';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import {grad1, grad2, grad3, grad4, grad5, grad6, grad7, grad8, grad9, grad10} from './data_resume';
import { linkResume, linkJamar, linkHackathon, linkVideo } from './data_resume';

const Resume = ({}) => {
    const history = useHistory();
    const navigateTo = () => history.push('/projects');
    const classes = useStyles();
    const [openLander, setOpenLander] = React.useState(false);
    const [openCert, setOpenCert] = React.useState(false);
    const [openGrad, setOpenGrad] = React.useState(false);
    const handleClickGrad = () => {
        setOpenGrad(!openGrad);
    };
    const handleOpenLander = () => {
        setOpenLander(true);
    };
    const handleOpenCert = () => {
        setOpenCert(true);
    };
    const handleClose = () => {
        setOpenLander(false);
        setOpenCert(false);
        setOpenGrad(false);
    };

    const album = [grad1, grad2, grad3, grad4, grad5, grad6, grad7, grad8, grad9, grad10];
    const [slideIn, setSlideIn] = React.useState(true);
    const [slideDirection, setSlideDirection] = React.useState('down');
    const [index, setIndex] = React.useState(0);
    const content = album[index];
    const numSlides = album.length;

    function Arrow(props) {
        const { direction, clickFunction } = props;
        const icon = direction === 'left' ? <FaChevronLeft /> : <FaChevronRight />;
        return <div className={classes.svg} onClick={clickFunction}>{icon}</div>;
    }
    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (index + increment + numSlides) % numSlides;
        
        const oppDirection = direction === 'left' ? 'right' : 'left';
        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            setIndex(newIndex);
            setSlideDirection(oppDirection);
            setSlideIn(true);
        }, 500);
        // setIndex(newIndex);
    };

    return (
        <Grid style={{marginLeft: '0.3rem'}}>
            <Box component='header' className={classes.mainContainer}>
                {/* Heading Tag*/}
                <div className={classes.startheading}>
                    <Typography 
                        variant='h4' 
                        align='center'
                        className={classes.heading}>
                        <Typed strings={['Working experience']} typedSpeed={10} style={{fontFamily: '\'Architects Daughter\', cursive'}}/>    
                    </Typography>   

                    <Link className={classes.resume} variant='body1' aligh='center' style={{color: 'tomato'}} href={linkResume} target='_blank' rel="noreferrer">
                        My Resume
                    </Link>
                </div>
                
                {/* Experience starts from here*/}  
                <Box component="div" className={classes.timeLine}>
                    {/* First Experience*/}
                    <Typography 
                        variant="h2" 
                        className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        6/2020 - 6/2022
                    </Typography>
                    <Box component="div" className={classes.timeLineItem}>
                        <Typography 
                            variant='h5' 
                            aligh='center' 
                            className={classes.subHeading}>
                            University of California, Los Angeles, US
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                            UCLA Henry Samueli School of Engineering and Applied Science
                        </Typography>                        
                        <Box 
                        component='div'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            justifyContent: 'space-between',
                            fontFamily: '\'Noto Sans JP\', sans-serif'
                        }}>
                            <Typography variant='subtitle1'  style={{color: 'tan'}}>
                                I <Link onClick={handleClickGrad}>graduated</Link> student at UCLA with a computer science major, and looking for a full-time job.
                                &nbsp;<a href={linkVideo} target='_blank'>Link Video</a>
                            </Typography>


                            <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                                Research Assistant, Cyber manufacturing team (10/2020 - 1/2021)
                            </Typography>

                            <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                                +&nbsp; Collaborated with 3 teammates to develop a React JS web application as a GUI to drive an electronic system.<br/>
                                +&nbsp; Advancing the cloud-based cyber-manufacturing ecosystem for digital microfluidics.<br/>
                                +&nbsp; Designing the principles and architecture of a software-hardware coordination system.<br/>
                                <br/>
                            </Typography>


                            <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                                Winner at Hack the Innovation Hackathons (9/2020)
                            </Typography>

                            <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                                +&nbsp; Created a platform where students can expand their network, learn new skills, showcase projects, 
                                and interact with the recruiters easier.<br/>
                                +&nbsp; Developed the front-end side by combining the React components such as Material-UI, 
                                React-Modal with the Redux library.<br/>
                                +&nbsp; Working closely with the back-end side teammate to release the solution for the website's progress.<br/>
                                <div className={classes.root}>
                                    <Button variant="contained" color="primary" href={linkHackathon} target='_blank'>
                                        Source
                                    </Button>
                                </div>
                                <br/>
                            </Typography>

                            <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                                Projects (6/2020 - 8/2020)
                            </Typography>   
                           
                            <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                                +&nbsp; Participate in the Transfer Bridge Program of UCLA for reviewing and more
                                deeply the Data Structure in C++ under the instruction of UCLA's mentor.<br/>
                                +&nbsp; I took many online courses as well as
                                learning myself about JavaScript, React, and Python that helped me build
                                approximately 20 mini projects updated on my personal website.<br/>
                                <div className={classes.root}>
                                    <Button variant="contained" color="primary" onClick={navigateTo} target='_blank'>
                                        My projects
                                    </Button>
                                </div>
                                +&nbsp; Finishing my front-end of the personal website
                                by using React combine with React material UI and understanding the
                                difference between using class and function components. Fluently in using
                                Redux and Hook in React. Now, I continue working with the back-end side and
                                will use firebase to store my website's data.
                            </Typography>
                            <Dialog
                                open={openGrad}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <div className={classes.volunteer}>
                                    <Arrow
                                        direction='left'
                                        clickFunction={() => onArrowClick('left')}
                                    />   
                                    <Slide in={slideIn} direction={slideDirection}>
                                        <div>
                                            <ShowVolunteer content={{backgroundImage: content}}></ShowVolunteer>
                                        </div>
                                    </Slide>
                                    <Arrow
                                        direction='right'
                                        clickFunction={() => onArrowClick('right')}
                                    />
                                </div>
                            </Dialog>
                        </Box>
                    </Box>
                    
                    <Typography 
                        variant="h2" 
                        className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        6/2021 - 9/2021
                    </Typography>
                    <Box component="div" className={classes.timeLineItem}>
                        <Typography 
                            variant='h5' 
                            aligh='center' 
                            className={classes.subHeading}>
                            Internship at AMAZON, US
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                            Software Engineering
                        </Typography>                        
                        <Box 
                        component='div'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            justifyContent: 'space-between',
                            fontFamily: '\'Noto Sans JP\', sans-serif'
                        }}>

                            <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                                +&nbsp; Built a new feature for the AgOrderDashboard service helping the Project Manager keep track of the orders information based on benefit program specifically
                                for AgsPrime and GlobalStoreFreeShipping orders.<br/>
                                <br/>
                                +&nbsp; Used basic Angular JS to display logo/image and add a dropdown button for the benefits program.<br/>
                                <br/>
                                +&nbsp; Worked with Hibernate framework guided the web application map Java object-oriented to relational MySQL DB.<br/>
                                <br/>
                                +&nbsp; Investigated the N+1 problem in Hibernate and Spring Data JPA that caused the latency on dashboard service.<br/>
                                <br/>
                                +&nbsp; Developed a utils method that determined and classified the benefits program by extracting Prime Eligibility and Delivery Program Policy entity information of orders from the Amazon PCE API.<br/>
                                <br/>
                                +&nbsp; Used Mockito framework to create the unit tests that make sure the lines will be covered up to 95%.<br/>
                                <br/>
                                +&nbsp; Used MySQL Workbench tool and SQL to query and test for DB having more than 160 million rows to make sure the DB is updated correctly.<br/>
                                <br/>
                            </Typography>
                        </Box>
                    </Box>



                    {/* First 1 Experience*/}
                    <Typography 
                        variant="h2" 
                        className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        9/2019 - 11/2019
                    </Typography>
                    <Box component="div" className={classes.timeLineItem}>
                        <Typography 
                            variant='h5' 
                            aligh='center' 
                            className={classes.subHeading}>
                            NASA L'SPACE ACADEMY, US
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                            Mission Concept Academy
                        </Typography>                        
                        <Box 
                        component='div'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            justifyContent: 'space-between',
                            fontFamily: '\'Noto Sans JP\', sans-serif'
                        }}>
                            <Typography variant='subtitle1'  style={{color: 'tan'}}>
                                <span style={{color: 'tomato'}}>Project:</span>&nbsp;Design a small spacecraft that will examine the surface of Vesta asteroid,
                                and come back with the material composition of the asteroid.
                            </Typography>  
                            <br/>
                            <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                                +&nbsp;Working closely with teammates to make a preliminary schedule to build a lander.
                                Learning the general way to push the spacecraft out of the earth's gravitational pull and
                                successfully travel to the Vesta asteroid.<br/>
                                <br/>
                                +&nbsp;Using <Link href={linkJamar} target='_blank'>JMARS</Link> (Java Mission-planning and Analysis for Remote Sensing), Science Community
                                Tool – J-Asteroid, to simulate the surface of the asteroid under the instruction of  NASA's engineering.<br/>
                                <br/>
                                +&nbsp;Though hard-working in 12 weeks could not make me a professional space engineering but gave me
                                an excellent opportunity to grow mine academically, working with great people and professionals in teamwork skills.
                            </Typography>
                            <div className={classes.root}>
                                <Button variant="contained" color="primary" onClick={handleOpenLander}>
                                    Lander's Design
                                </Button>
                                <Button variant="contained" color="secondary" onClick={handleOpenCert}>
                                    Certificate
                                </Button>
                            </div>
                            <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openLander}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            >
                                <Fade in={openLander}>
                                    <div className={classes.paper}>
                                        <img className={classes.image} src={lander} alt="Lander\'s Design" with='350px' height='350px'/>
                                    </div>
                                </Fade>
                            </Modal>
                            <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openCert}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            >
                                <Fade in={openCert}>
                                    <div className={classes.paper}>
                                        <img className={classes.image} src={cert} alt='Certificate' with='350px' height='350px'/>
                                    </div>
                                </Fade>
                            </Modal>
                        </Box>
                    </Box>

                    {/* Second Experience*/}
                    <Typography 
                        variant="h2" 
                        className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        9/2018 - 6/2020
                    </Typography>
                    <Box component="div" className={classes.timeLineItem}>
                        <Typography 
                            variant='h5' 
                            aligh='center' 
                            className={classes.subHeading}>
                                Santa Ana College, US
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                            Computer Science Department
                        </Typography>
                        <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                            I started studying computer science major at Santa Ana College
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                                Experiences
                        </Typography>   
                        <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                            +&nbsp; Becoming a tutor helped me have a great opportunity to improve my public 
                            speaking by interacting with the people around me every day, and help them
                            in the review knowledge in C++ and Java reinforced my programming skill as well.<br/>
                            <br/>
                            +&nbsp; Joined the Computer Science Club and "Come Fly with Me" event that I used
                            Python programming to operate the drone control infrastructure.<br/>
                            <br/>
                        </Typography>
                    </Box>
                    
                    {/* Third Experience*/}
                    <Typography 
                        variant="h2" 
                        className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
                        12/2014 - 7/2017
                    </Typography>
                    <Box component="div" className={classes.timeLineItem}>
                        <Typography 
                            variant='h5' 
                            aligh='center' 
                            className={classes.subHeading}>
                                DEK Technologies, Viet Nam
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                            Software Engineering
                        </Typography>
                        <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                            I joined in Linux team that worked with the LDE (Linux Distribution Extension) project.<br/>
                        </Typography>
                        <Typography variant='body1' aligh='center' style={{color: 'tomato'}}>
                            Experiences
                        </Typography>
                        <Typography variant='subtitle1' aligh='center' style={{color: 'tan'}}>
                            +&nbsp;Basically, LDE is a family of products that provide Linux systems with additional functionality
                            such as multi-blade management of service configuration and software as well as clustering with high
                            availability characteristics. A cluster is a group of blades, that are independent computers with its
                            own processor, memory, network interfaces, and so on.<br/>
                            <br/>
                            +&nbsp;As a software engineering, working with C/C++ and Shell Script, we investigated the issue that was
                            reported by the customer, proposed and reviewed implementation solutions, and supported customers
                            on any queries and questions.<br/><br/>
                        </Typography>
                    </Box> 
                </Box>
            </Box>
        </Grid>
    )
}

export default Resume;