import React, {useState} from 'react';  
/**********************************************************************/
import Home from './components/home/home.component';
import Resume from './components/resume/resume.component';
import Contacts from './components/contact/contact.component';
import Clock from '../src/components/navbar/clock.component.jsx';
import Navbar from '../src/components/navbar/navbar.component';
import {Route} from 'react-router-dom';
import Projects from './components/project/projects.component';
import SignIn from './components/signIn/sign-in';
import AboutMe from './components/aboutme/aboutme';
import {Courses} from './components/courses/courses';
import ReactFirebase from './components/project/react_firebase/react_firebase.jsx';
/********************************************************************/
import './App.css';
import image1 from './components/images/background/back1.jpg';
import image2 from './components/images/background/back2.jpg';
import image3 from './components/images/background/back3.jpg';
import image4 from './components/images/background/back4.jpg';
import image5 from './components/images/background/back5.jpg';
import image6 from './components/images/background/back6.jpg';
import image7 from './components/images/background/back7.jpg';
import image8 from './components/images/background/back8.jpg';
import image9 from './components/images/background/back9.jpg';
import image10 from './components/images/background/back10.jpg';
import image11 from './components/images/background/back11.jpg';
import image12 from './components/images/background/back12.jpg';
import image13 from './components/images/background/back13.jpg';
import image14 from './components/images/background/back14.jpg';
/******************************************************************* */
import Cplus from '../src/components/project/c++/cplus.jsx';
import Java from '../src/components/project/java/java.jsx';
// import Python from '../src/components/project/c++/cplus.jsx';
import Js from '../src/components/project/js/js.jsx';
import ReactApp from '../src/components/project/react-app/react.jsx';
/******************************************************************* */
function App() {
  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14];
  const [state, setState] = useState(image1);
  var backgroundURL = state;
  var styles = {
    // backgroundImage: `url(${state})`,
    backgroundImage: `url(${backgroundURL})`,
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }

  const second = () => {
    setTimeout( ()=> { // timer still runs in the background
        let number = Math.round((Math.random()*13));
        let img = images.filter((img, index)=> index===number);
        setState(img);
    }, 10000);
  };  
  second();

  return (
    <div className='App' style={styles}>
      <main>
        <Clock className='clock'></Clock>
        <Navbar/>
        <Route exact path='/' component={Home} />
        <Route exact path='/resume' component={Resume} />
        <Route exact path='/projects' component={Projects} />
        <Route exact path='/projects/c++' component={Cplus} />
        <Route exact path='/projects/java' component={Java} />
        <Route exact path='/projects/js' component={Js} />
        <Route exact path='/projects/react' component={ReactApp} />
        <Route exact path='/projects/react_firebase' component={ReactFirebase} />
        <Route exact path='/signin' component={SignIn} />
        <Route exact path='/contacts' component={Contacts} />
        <Route exact path='/aboutme' component={AboutMe} />
        <Route exact path='/mycourses' component={Courses} />
      </main>
    </div>
  );
}

export default App;
