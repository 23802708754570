/**************************************************************************************/
import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import {BottomNavigation, BottomNavigationAction, Box} from '@material-ui/core';
/**************************************************************************************/
import Face from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import Instagram from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
/**************************************************************************************/
const gitLink = 'https://github.com/nhattanho';
const faceLink = 'https://www.facebook.com/nhattanho24/';
const linkedLink = 'https://www.linkedin.com/in/nhatho24/';
const instaLink = 'https://www.instagram.com/nhattan.ho/?hl=vi';
const youtubeLink = 'https://www.youtube.com/channel/UC6SmN_XPGFO5Sum5n1EVZjQ';
const target = '_blank';
/**************************************************************************************/
const useStyles = makeStyles(theme => ({
    socialHome: {
        zIndex: 1,
        position: 'relative',
        borderRadius: '30px',
        backgroundColor: 'rgb(26, 13, 13)',
        boxShadow: '0px 5px rgba(208, 33, 33, 0.2)',
        backgroundImage: 'linear-gradient(to right bottom, #1C1911, #371A1A)',
        [theme.breakpoints.down('sm')]: {
            width: '320px',
            display: 'grid',
            gridTemplateColumns: '60px 60px 60px 60px auto'
        }
    },
    root: {
        position:'absolute',
        fill: 'tan', 
        '&:hover': {
            fill: 'tomato !important',
            fontSize: '1.5rem !important'
        },
        [theme.breakpoints.down('sm')]: {
            //marginTop: '-15px !important',
            fontSize: 30
        }
    }
}));

const Footer = ({input}) => {
    const classes = useStyles();
    return (
        <Box className={classes.container} style={{alignItems: 'center', display:'flex',
        flexDirection:'column', position:'absolute', top:'80%',
        left:'50%', transform:'translate(-50%, -50%)', zIndex: 1}}>
            <BottomNavigation className={classes.socialHome}>
                    <BottomNavigationAction
                    component={Button}
                    href={linkedLink}
                    target={target}
                    // style={{padding: '0.5rem'}}
                    icon={<LinkedInIcon className={classes.root}/>}
                    />
                    <BottomNavigationAction
                        component={Button}
                        href={gitLink}
                        target={target}
                        // style={{padding: '0.5rem'}}
                        icon={<GitHubIcon className={classes.root}/>}
                    />
                    <BottomNavigationAction
                        component={Button}
                        href={instaLink}
                        target={target}
                        // style={{padding: '0.5rem'}}
                        icon={<Instagram className={classes.root}/>}
                    />
                    <BottomNavigationAction
                        component={Button}
                        href={youtubeLink}
                        target={target}
                        // style={{padding: '0.5rem'}}
                        icon={<YouTubeIcon className={classes.root}/>}
                    />
                    <BottomNavigationAction
                        component={Button}
                        href={faceLink}
                        target={target}
                        // style={{padding: '0.5rem'}}
                        icon={<Face className={classes.root}/>}
                    />
            </BottomNavigation>
        </Box>
    )
}

export default Footer;