const data = [
    {
        name: 'C++',
        intro: 'This repository contains my experiences in C++',
        linkback: '../project/images/backc++.jpg',
        link: '/c++'
    },
     {
        name: 'JAVA',
        intro: 'My experiences in JAVA',
        linkback: '../project/images/backjava.jpg',
        link: '/java'
    },
    {
        name: 'PYTHON',
        intro: 'My experiences in PYTHON',
        linkback: '../project/images/backpython.jpg',
        link: '/python'
    },
    {
        name: 'JAVASCRIPT',
        intro: 'My experiences in JAVASCRIPT',
        linkback: '../project/images/backjs.jpg',
        link: '/js'
    },
    {
        name: 'REACT',
        intro: 'My experiences in REACT',
        linkback: '../project/images/backreact.jpg',
        link: '/react'
    },
    {
        name: 'REACT + FIREBASE + AUTHORIZATION',
        intro: 'My experiences in combining React and Firebase',
        linkback: '../project/images/backFirebaseReact.jpg',
        link: '/react_firebase'
    },
]

export default data;