const data = [
    {
        name: 'C++ PART I',
        intro: 'Introduction to Programming in C++',
        linkback: './part1.jpg',
        link: 'https://github.com/nhattanho/CMPR120'
    },
     {
        name: 'C++ PART II',
        intro: 'Diving deeper in C++ ',
        linkback: './part2.jpg',
        link: 'https://github.com/nhattanho/CMPR121'
    },
    // {
    //     name: 'DATA STRUCTURE & ALGORITHMS',
    //     intro: 'Practicing to become master in DS&ALGO',
    //     linkback: './ds.png',
    //     link: '/projects/ds'
    // },
    {
        name: 'LEETCODE',
        intro: 'Cracking the coding Interview',
        linkback: './leetcode.png',
        link: 'https://github.com/nhattanho/LeetcodePractices'
    }
]

export default data;