import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { makeStyles} from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import {Content} from './content.jsx';
import {sac, ucla} from './data_courses';

const useStyles = makeStyles(theme => ({
  maincourse: {
    display: "flex",
    flexDirection: "row",

  },
  courses: {
    width: "50%",
    height:"100%",
    textAlign: "center",
  },
  name: {
    color: 'tomato',
    textTransform: 'uppercase',
    background: 'linear-gradient(to top, #7fc27c70, #3b262633);',
    border: '2px solid black',
    borderRadius: '40px',
    boxShadow: 'inset 0 20px 4px -19px rgba(255, 255, 255, 0.4), 0 12px 12px 0 rgba(0, 0, 0, 0.3)',
    padding: "100px 30px 25px 75px",
    boxSizing:"content-box",
    marginBottom: "10px",
    [theme.breakpoints.up('md')]: {
        width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '90%',
        fontSize: '25px',
    }
  },

}));

export const Courses = () => {
  const classes = useStyles();
  return (
    <div className={classes.maincourse}>
      <div className={classes.courses}>
        <div className={classes.name}>
          Santa Ana College
        </div>
        {sac.map( (data, index) => (
          <Content 
          name={data.name} 
          link={data.source} 
          key={data.index}
          documentlink={data.documentlink}
          statusdemo={data.statusdemo}
          linkdemo={data.linkdemo}
          />
        )
        )}
      </div>

      <div className={classes.courses}>
        <div className={classes.name}>
          University of California, Los Angeles
        </div>
        {ucla.map( (data, index) => (
          <Content 
          name={data.name} 
          link={data.source} 
          key={data.index}
          documentlink={data.documentlink}
          statusdemo={data.statusdemo}
          linkdemo={data.linkdemo}
          />
        )
        )}
      </div>

    </div>
  )
}
