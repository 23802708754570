import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core';
/**************************************************************************************/
import Face from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
import Instagram from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
/**************************************************************************************/
const gitLink = 'https://github.com/nhattanho';
const faceLink = 'https://www.facebook.com/nhattanho24/';
const linkedLink = 'https://www.linkedin.com/in/nhatho24/';
const instaLink = 'https://www.instagram.com/nhattan.ho/?hl=vi';
const youtubeLink = 'https://www.youtube.com/channel/UC6SmN_XPGFO5Sum5n1EVZjQ';
const target = '_blank';
/**************************************************************************************/
const useStyles = makeStyles({
    root: {
        '& .MuiBottomNavigationAction-root':{
            minWidth: 0,
            maxWidth: '240px',
            marginTop: '-10px !important'
        },
        '& .MuiSvgIcon-root': {
            fill: 'tan',
            "&:hover": {
                fill: 'tomato',
                fontSize: '1.5rem;'
            }
        }
    },
    socialSlide: {
        marginLeft: '-2rem',
        position: 'relative',
        display: 'grid !important',
        justifySelf: 'start',
        gridTemplateColumns: '45px 45px 45px 45px',
    },
});

const Footer = () => {

    const classes = useStyles();
    return (
        <BottomNavigation width='14rem' style={{background: '#222', height: '120px'}} className={classes.socialSlide}>
                <BottomNavigationAction
                className={classes.root}
                component={Button}
                href={linkedLink}
                target={target}
                style={{padding: '0.5rem'}}
                icon={<LinkedInIcon/>}
                />
                <BottomNavigationAction
                    className={classes.root}
                    component={Button}
                    href={gitLink}
                    target={target}
                    style={{padding: '0.5rem'}}
                    icon={<GitHubIcon/>}
                />
                <BottomNavigationAction
                    className={classes.root}
                    component={Button}
                    href={instaLink}
                    target={target}
                    style={{padding: '0.5rem'}}
                    icon={<Instagram/>}
                />
                <BottomNavigationAction
                    className={classes.root}
                    component={Button}
                    href={youtubeLink}
                    target={target}
                    style={{padding: '0.5rem'}}
                    icon={<YouTubeIcon/>}
                />
                <BottomNavigationAction
                    className={classes.root}
                    component={Button}
                    href={faceLink}
                    target={target}
                    style={{padding: '0.5rem'}}
                    icon={<Face/>}
                />

        </BottomNavigation>
    )
}

export default Footer;