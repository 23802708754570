const data = [
    {
        name: 'REACT JS',
        intro: 'Practicing in using React JS',
        link: 'https://github.com/nhattanho/firstReact/tree/master/Practice_React',
        demo: ''
    },
    {
        name: 'COVID-19 Tracker App',
        intro: 'The mini App helps us tracker the covid-19 situation in the world',
        link: 'https://github.com/nhattanho/React1_CovidTracker/tree/master',
        demo: 'https://nhattanho.github.io/React1_CovidTracker/'
    },
    {
        name: 'Weather Tracker App',
        intro: 'This mini App helps us update the weather of capitals',
        link: 'https://github.com/nhattanho/React2_Weather/tree/master',
        demo: 'https://nhattanho.github.io/React2_Weather/'
    },
    {
        name: 'Find Monsters',
        intro: 'This is practicing with modules and handling the simple event in React',
        link: 'https://github.com/nhattanho/React3_Monsters/tree/master',
        demo: 'https://nhattanho.github.io/React3_Monsters/'
    },
    {
        name: 'Infinite Scroll',
        intro: 'This is a simple of using React Hook',
        link: 'https://github.com/nhattanho/React4_InfiniteScroll1',
        demo: 'https://nhattanho.github.io/React4_InfiniteScroll1/'
    }
]

export default data;