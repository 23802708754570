import React, {Component} from 'react';  
import './clock.style.css';

class Clock extends Component {
  state = {
    time: "00:00:00",
    amPm: "am"
  }

  mounted = false;

  componentWillUnmount() {
    // console.log('I am unmounted');
    this.mounted = false;
  }

  componentDidMount () {
    // console.log('I mounted');
    this.mounted =  true;
  	setInterval(this.getTime, 1000);
  }

  takeTwelve = (n) => n > 12 ?  n  - 12 : n;
  addZero = (n) => n < 10 ? "0" +  n : n;

  getTime = this.getTime.bind(this);//have to call bind this, because when we pass the
  // this.takeTwelve in this.getTime <=> this is in second level, so it cann't understand
  // the object this of class
  getTime () {    
    	let d, h, m, s, t, amPm;
      d = new Date();
      let hours = d.getHours();
      let getTwelve = this.takeTwelve(hours);
      h = this.addZero(getTwelve); 
      m = this.addZero(d.getMinutes()); 
      s = this.addZero(d.getSeconds());
		  t = `${h}:${m}:${s}`;
      amPm = d.getHours() >= 12 ? "pm" : "am";
      if(this.mounted){
        this.setState({
          time: t, 
          amPm: amPm
        });
      } 
  }

	render () {
  	return (
      <div className="outer">
          <div className="most-inner">
            <span className={
              this.state.time === "00:00:00" 
                ? "time blink" 
                : "time"} 
            > {this.state.time}
            </span>
            <span className="amPm">
              {this.state.amPm}
            </span>
          </div>
      </div>
    );
  }
};

export default Clock;