import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ParticlesBg from "particles-bg";
import Tooltip from '@material-ui/core/Tooltip';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {/*'Copyright © '*/}
      <Link color="inherit" to="/">
        Welcome to my website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  signin: {
    position: 'relative',
    marginTop: '-3.9rem',
    height: '100%',
  },
  mainform: {
    backgroundImage: 'linear-gradient(to bottom, rgba(232, 224, 224, 0.6), rgba(149, 138, 138, 0.75))',
    backgroundColor: 'rgb(250, 255, 255, 0.625)',
    borderRadius: '10px',
    maxWidth: '400px !important',
    position: 'relative',
    zIndex: 2,
    top: '6rem',
    height: '100% !important',
    [theme.breakpoints.down('sm')]: {
      // top: '5rem'
      width: '90%',
      top: '65px'
    }
  },
  particle: {
    position: 'absolute',
    top: '64px',
    left: '0px',
    zIndex: '1 !important',
    width: '100% !important',
    height: '100vh !important',
    lineHeight: 'inherit !important',
    [theme.breakpoints.down('sm')]: {
      // top: '5rem'
      top: '46px',
      height: '100% !important'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  boxcopy: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px'
    }
  }
}));

const SignIn = () => {
  const classes = useStyles();

  return (
    <div className={classes.signin}>
      <Container className={classes.mainform} maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Tooltip title='Note: I am working on this component'> 
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            </Tooltip> 
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8} className={classes.boxcopy}>
          <Copyright />
        </Box>
      </Container>
      <div className={classes.particle}>
        <ParticlesBg  type="lines" bg={true}></ParticlesBg>
      </div>
      {/* Types:"color"
                "ball"
                "lines"
                "thick"
                "circle"
                "cobweb"
                "polygon"
                "square"
                "tadpole"
                "fountain"
                "random"
                "custom" */}
    </div>
    );
}

export default SignIn;