import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { makeStyles} from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

export const Content = (props) => {
    const {name, link, documentlink, statusdemo, linkdemo } = props;
    return (
        <Paper
        sx={{
          p: 2,
          alignContent:"center",
          textAlign:"center",
          margin: 'auto',
          maxWidth: 500,
          flexGrow: 1,
          marginBottom: "10px",
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#dcc7c7' : '#d9bfbf',
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <ButtonBase sx={{ width: 128, height: 128 }}>
              <Img alt="complex" src={link} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                    {name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <a href={documentlink} target='_blank'>My Document and source</a>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ cursor: 'pointer' }} variant="body2">
                  {statusdemo &&  <a href={linkdemo} target="_blank">Demo</a> }
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        </Paper>
    )
}
