import React from 'react';
import {
    Box, Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
/**********************************************************/
import { bounce} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
// import color from 'color';
import styled, { keyframes } from "styled-components";
import SlideInDown from "@bit/formidablelabs.react-animations.slide-in-down";
/**********************************************************/
import data from './data';
import cplus1 from './part1.jpg';
import cplus2 from './part2.jpg';
import ds from './ds.png';
import leet from './leetcode.png';
/**********************************************************/
const SlideInDownAnimation = keyframes`${SlideInDown}`;
const SlideInDownDiv = styled.div`
  animation: 5s ${SlideInDownAnimation};
`;
/**************Animation for StyleRoot******************/
const styles = {
    bounce: {
      animation: 'x 2s',
      animationName: Radium.keyframes(bounce, 'bounce')
      },
      bounceCard: {
        fontWeight: 'bolder',
        fontSize: '1rem !important',
        fontFamily: '\'Architects Daughter\', cursive',
        color: 'rgba(0, 0, 0, 0.9).lighten(0.2).hex()',
        transitionDuration: '.2s', 
        transitionTimingFunction: 'ease-out'
      }
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        //background: '#233',
        position: 'relative',
        marginTop:'0px',
        height: '100%',
        width: '99%',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            top: '20px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px'
        }
    },
    container: {
        padding: '5%',
    },
    cardContainer: {
        //maxWidth: 345,
        minHeight: 300,
        maxHeight: 300,
        marginTop: '2rem',
        borderRadius: '20px',
        [theme.breakpoints.up('md')]: {
            minWidth: '280px'
        }
    },
    particle: {
        position: 'absolute',
        zIndex: 1,
        opacity: '0,2'
    }
}))

const image = [cplus1, cplus2, ds, leet];
const Cplus = () => {
    const classes = useStyles();
    return (
        <Box className={classes.mainContainer}>
            <Grid container className={classes.container} justify='center' alignItems='center' spacing={2}>
                {data.map( (cur, index) => 
                    (<Grid item xs={12} sm={2} md={4} key={index}>
                        <StyleRoot style={styles.bounce}>
                            <div >
                                <Card className={classes.cardContainer} >
                                    <SlideInDownDiv>
                                        <CardActionArea>
                                            <CardMedia component='img' alt={cur.name} height='140' image={image[`${index}`]}/>
                                            <CardContent>
                                                <Typography gutterBottom variant='h5'>
                                                    {cur.name}
                                                </Typography>
                                                <Typography variant='body2' color='textSecondary' component='p' style={styles.bounceCard}>
                                                    {cur.intro}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size='small' variant="contained" color="primary" href={cur.link} target='_blank'>
                                                    Source Code
                                                </Button>
                                            </CardActions>
                                        </CardActionArea>
                                    </SlideInDownDiv>
                                </Card>
                            </div>
                        </StyleRoot> 
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Cplus;

