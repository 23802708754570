import {makeStyles, withStyles} from '@material-ui/core/styles';
import {TextField} from '@material-ui/core';

export const useStyles = makeStyles(theme=>({
    container: {
        top: '3rem', 
        position: 'relative',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('sm')]:{
            // top: '0rem',
            display: 'grid',
            alignItems: 'center',
            width: '100%',
            height: '100%'
            // marginTop: '40px'
        }
    },
    form: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
        width: '100vh',
        [theme.breakpoints.down('sm')]:{
            width: '90%',
            // marginTop: '40px'
        }
    },
    info: {
        marginTop: '1rem',
        borderRadius: '16px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        boxShadow: '3px 6px rgba(0, 0, 0, 0.2)',
        marginBottom: '1rem',
        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem !important',
            // width: '99%'
        }
    },
    item:{
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            marginLeft: '-1rem'
        }
    },
    button: {
        marginTop: '1.5rem',
        color: 'tomato',
        borderColor: 'tomato',
        display: 'flex',
        justifyContent: 'space-between',
        width: '180px',
        [theme.breakpoints.down('sm')]:{
            marginTop: '5px',
            height: '95%'
        }
    },
    heading: {
        [theme.breakpoints.down('sm')]:{
            marginRight: '-1.5rem'
        } 
    },
    input: {
        [theme.breakpoints.down('sm')]:{
            width: '90%',
            height: '100%'
        }
    },
    sentmessage: {
        color: '#4eca09',
        borderRadius: '16px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        boxShadow: '3px 6px rgba(67, 227, 10, 0.2)',
        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem !important',
            // width: '99%'
        }
    },
}))

/* Change the style of component in Material UI */
export const InputField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'tomato'
        },
        '& label': {
            color: 'tan'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'tan'
            },
            '&:hover fieldset': {
                borderColor: 'tan'
            },
            '& .Mui-focused fieldset': {
                borderColor: 'tan'
            }
        }
    },
})(TextField);