import React from 'react';
import {
    Box, Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
/**********************************************************/
import { bounce} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import styled, { keyframes } from "styled-components";
import LightSpeedIn from "@bit/formidablelabs.react-animations.light-speed-in";
/**********************************************************/
import data from './data';
import js from './js.jpg';
import js1 from './JS1.PNG';
import js2 from './JS2.PNG';
import js3 from './JS3.PNG';
import js4 from './JS4.PNG';
import js5 from './js5.PNG';
/**********************************************************/
const LightSpeedInAnimation = keyframes`${LightSpeedIn}`;
const LightSpeedInDiv = styled.div`
  animation: 3s ${LightSpeedInAnimation};
`;
/**************Animation for StyleRoot******************/
const styles = {
    bounce: {
      animation: 'x 2s',
      animationName: Radium.keyframes(bounce, 'bounce')
      },
      bounceCard: {
        fontWeight: 'bolder',
        fontSize: '1rem !important',
        fontFamily: '\'Architects Daughter\', cursive',
        color: 'rgba(0, 0, 0, 0.9).lighten(0.2).hex()',
        transitionDuration: '.2s', 
        transitionTimingFunction: 'ease-out'
      }
}

const useStyles = makeStyles(theme =>({
    mainContainer: {
        //background: '#233',
        position: 'relative',
        height: '100%',
        width: '99%',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        marginTop:'0px',
        [theme.breakpoints.down('sm')]: {
            top: '20px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px'
        }
    },
    container: {
        padding: '5%',
    },
    cardContainer: {
        minHeight: 300,
        maxHeight: 310,
        marginTop: '2rem',
        borderRadius: '20px'
    },
    particle: {
        position: 'absolute',
        zIndex: 1,
        opacity: '0,2'
    }
}))

const image = [js, js1, js2, js3, js4, js5];
const Js = () => {
    const classes = useStyles();
    return (
        <Box className={classes.mainContainer}>
            <Grid container className={classes.container} justify='center' alignItems='center' spacing={2}>
                {data.map( (cur, index) => 
                    (<Grid item xs={12} sm={2} md={4} key={index}>
                        <StyleRoot style={styles.bounce}>
                            <Card className={classes.cardContainer} >
                                    <LightSpeedInDiv>
                                        <CardActionArea>
                                            <CardMedia component='img' alt={cur.name} height='140' image={image[`${index}`]}/>
                                            <CardContent>
                                                <Typography gutterBottom variant='h5'>
                                                    {cur.name}
                                                </Typography>
                                                <Typography variant='body2' color='textSecondary' component='p' style={styles.bounceCard}>
                                                    {cur.intro}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size='small' variant="contained" color="primary" href={cur.link} target='_blank'>
                                                    Source Code
                                                </Button>
                                                {
                                                    (cur.demo) ? 
                                                    (<Button size='small' variant="contained" color="primary" href={cur.demo} target='_blank'>
                                                        Demo
                                                    </Button>) : null
                                                }
                                            </CardActions>
                                        </CardActionArea>
                                    </LightSpeedInDiv>
                            </Card>
                        </StyleRoot> 
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default Js;

