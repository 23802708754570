const data = [
    {
        name: 'JavaScript',
        intro: 'Introduction programming in JS',
        link: 'https://github.com/nhattanho/firstReact/tree/master/Practice_JavaScript',
        demo: ''
    },
    {
        name: 'Infinite Scroll',
        intro: 'Mini App calls API images and displays on-screen with infinite Scroll functionality',
        link: 'https://github.com/nhattanho/JS1_InfiniteScroll/tree/master',
        demo: 'https://nhattanho.github.io/JS1_InfiniteScroll/'
    },
    {
        name: 'Quote Genarator',
        intro: 'Mini App calls API images, and displays them on-screen as well as can be changed by the users.',
        link: 'https://github.com/nhattanho/JS2_QuoteGenarator/tree/master',
        demo: 'https://nhattanho.github.io/JS2_QuoteGenarator/'
    },
    {
        name: 'Shooting Dice Mini Game',
        intro: 'The mini-game for shooting dice',
        link: 'https://github.com/nhattanho/JS3_ShotDiceGame/tree/master',
        demo: 'https://nhattanho.github.io/JS3_ShotDiceGame/'
    },
    {
        name: 'Budget App',
        intro: 'This is a funny Budget App helps us manage the income',
        link: 'https://github.com/nhattanho/JS4_BudgetApp/tree/master',
        demo: 'https://nhattanho.github.io/JS4_BudgetApp/'
    },
    {
        name: 'PiP feature',
        intro: 'The Picture-in-Picture API help users create the floating video',
        link: 'https://github.com/nhattanho/JS5_PiP/tree/master',
        demo: 'https://nhattanho.github.io/JS5_PiP/'
    }
]

export default data;