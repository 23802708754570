import React from 'react';
import {
    Box, Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
/**********************************************************/
import { bounce} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import styled, { keyframes } from "styled-components";
import FlipInX from "@bit/formidablelabs.react-animations.flip-in-x";
/**********************************************************/
import data from './data';
import react from './react.png';
import covid from './covid.PNG';
import weather from './weather.PNG';
import monster from './monster.PNG';
import infinite1 from './infinite1.PNG';
/**********************************************************/
const FlipInXAnimation = keyframes`${FlipInX}`;
const FlipInXDiv = styled.div`
  animation: 3s ${FlipInXAnimation};
`;
/**************Animation for StyleRoot******************/
const styles = {
    bounce: {
      animation: 'x 2s',
      animationName: Radium.keyframes(bounce, 'bounce')
      },
      bounceCard: {
        fontWeight: 'bolder',
        fontSize: '1rem !important',
        fontFamily: '\'Architects Daughter\', cursive',
        color: 'rgba(0, 0, 0, 0.9).lighten(0.2).hex()',
        transitionDuration: '.2s', 
        transitionTimingFunction: 'ease-out'
      }
}

const useStyles = makeStyles(theme =>({
    mainContainer: {
        //background: '#233',
        position: 'relative',
        height: '100%',
        width: '99%',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        marginTop:'0px',
        [theme.breakpoints.down('sm')]: {
            top: '20px'
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '0px'
        }
    },
    container: {
        padding: '5%',
    },
    cardContainer: {
        minHeight: 300,
        maxHeight: 300,
        marginTop: '2rem',
        borderRadius: '20px'
    },
    particle: {
        position: 'absolute',
        zIndex: 1,
        opacity: '0,2'
    }
}))

const image = [react, covid, weather, monster, infinite1];
const ReactApp = () => {
    const classes = useStyles();
    return (
        <Box className={classes.mainContainer}>
            <Grid container className={classes.container} justify='center' alignItems='center' spacing={2}>
                {data.map( (cur, index) => 
                    (<Grid item xs={12} sm={2} md={4} key={index}>
                        <StyleRoot style={styles.bounce}>
                            <div >
                                <Card className={classes.cardContainer} >
                                    <FlipInXDiv>
                                        <CardActionArea>
                                            <CardMedia component='img' alt={cur.name} height='140' image={image[`${index}`]}/>
                                            <CardContent>
                                                <Typography gutterBottom variant='h5'>
                                                    {cur.name}
                                                </Typography>
                                                <Typography variant='body2' color='textSecondary' component='p' style={styles.bounceCard}>
                                                    {cur.intro}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size='small' variant="contained" color="primary" href={cur.link} target='_blank'>
                                                    Source Code
                                                </Button>
                                                {
                                                    (cur.demo) ? 
                                                    (<Button size='small' variant="contained" color="primary" href={cur.demo} target='_blank'>
                                                        Demo
                                                    </Button>) : null
                                                }
                                            </CardActions>
                                        </CardActionArea>
                                    </FlipInXDiv>
                                </Card>
                            </div>
                        </StyleRoot> 
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ReactApp;

