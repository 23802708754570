import React from 'react';
import './home.style.css';
import FooterHome from '../footer/footer-home.component';
import Header from '../header/header.component';
import Particles from 'react-particles-js';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  particlesSide: {
    position: 'absolute',
    opacity: '0.9'
  }
})

const Home = () => {
  const classes = useStyles();
  return (
    <div>
        <Header></Header>
        <FooterHome></FooterHome>
        <Particles canvasClassName={classes.particlesSide}
          params={{
            detectRetina: true,
            fpsLimit: 30,
            particles: {
              size: {
                value: 3
              },
              shape: {
                type: 'circle',
                options: {
                  polygon: {
                    nb_sides: 5
                  },
                  start: {
                    nb_sides: 5
                  },
                  strole: {
                    width: 1,
                    color: 'tomato'
                  }
                }
              },
              color: {
                value: '#ff0000',
                animation: {
                  enable: true,
                  speed: 1,
                  sync: true
                }
              },
              collisions: {
                enable: false,
                mode: 'bounce'
              },
              number: {
                value: 80,
                density: {
                  enable: true,
                  area: 900,
                  factor: 1000
                }
              },
              opacity: {
                animation: {
                  enable: true,
                  speed: 3
                },
                value: 0.5
              },
              twinkle: {
                lines: {
                  enable: true,
                  frequency: 0.005,
                  opacity: 1,
                  color: {
                    value: '#ff0000'
                  }
                },
                particles: {
                  enable: true,
                  frequency: 0.05,
                  opacity: 1,
                  color: {
                    value: '#ffff00'
                  }                
                }
              },
              stroke: {
                color: {
                  value: '#000000',
                  animation: {
                    enable: false,
                    speed: 1,
                    sync: false
                  }
                }
              }
              
            },
            interactivity: {
              events: {
                resize: true,
                onClick: {
                  enable: true,
                  mode: 'push'
                },
                onDiv: {
                  id: [],
                  enable: true,
                  mode: [],
                  type: 'circle'
                },
                onHover: {
                  enable: true,
                  mode: 'repulse',
                  
                }
              }
            }
          }}
        >
        </Particles>
    </div>
  );
}

export default Home;
