/**************************************************************************************/
import React, {useState} from 'react';  
import {Link} from 'react-router-dom';
import {AppBar, Toolbar, ListItem, IconButton, ListItemText, Avatar, List, Typography, Box, ListItemIcon} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import MobilRightMenuSlider from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import styled, { keyframes } from "styled-components";
import Tada from "@bit/formidablelabs.react-animations.tada";
/**************************************************************************************/
import menuItems from './menuItems';
import Footer from '../footer/footer.component';
/**************************************************************************************/
import {ArrowBack} from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import avatar from '../images/avatar.jpg';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BadgeIcon from '@mui/icons-material/Badge';
/**************************************************************************************/
import './navbar.style.css';
/**************************************************************************************/
const useStyles = makeStyles(theme => ({
    homeContainer: {
        position: 'absolute',
        width: '100%',
        zIndex: 3
    },  
    listItem: {
        color: 'tan'
    },
    arrowback: {
        display: 'flex !important',
        justifyContent: 'start !important',
        position: 'absolute !important',
        left: '-4px !important',
        marginLeft: '-1.8px !important',
        '&:hover': {
            backgroundColor:  'rgb(240, 222, 222) !important',
            borderRadius: '60% !important',
            backgroundSize: '5px 5px !important'
        } 
    },
    headingNav: {
        position: 'absolute',
        left: '40px',
        color: 'tan',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px'
        }
    },
    homeicon: {
        position: 'absolute',
        marginLeft: '100px',
        marginRight: '1px',
        marginTop: '-20px',
        '&:hover': {
            backgroundColor:  'rgb(240, 222, 222)',
            borderRadius: '30%'
        }, 
        [theme.breakpoints.down('sm')]: {
            marginTop: '-15px',
            marginLeft: '90px',
            fontSize: 25,
        }
    },
    peopleicon: {
        position: 'absolute',
        margin: '-20px 0 0 165px',
        '&:hover': {
            backgroundColor:  'rgb(240, 222, 222)',
            borderRadius: '30%'
        },
        [theme.breakpoints.down('sm')]: {
            // marginTop: '5px',
            marginLeft: '110px',
            fontSize: 30
        }
    },  
    mycoursesicon:{
        position: 'absolute',
        marginLeft: '130px',
        marginRight: '1px',
        marginTop: '-22px',
        '&:hover': {
            backgroundColor:  'rgb(240, 222, 222)',
            borderRadius: '30%'
        }, 
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            marginTop: '-24px',
            marginLeft: '150px',
        }
    },
    accounticon: {
        marginTop: '-1rem',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: '12px',
        '&:hover': {
            backgroundColor:  'rgb(240, 222, 222)',
            borderRadius: '30%'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '-18px',
            marginRight: '-3px !important',
            fontSize: 30
        }
    }
}));

const TadaAnimation = keyframes`${Tada}`;
const TadaDiv = styled.div`
  animation: infinite 5s ${TadaAnimation};
`;

/***************************************************************************************/
const Navbar = () => {
    /**********************************************************************************/
    const classes = useStyles();  
    const [state, setState] = useState({
        right: false
    });

    const toggleSlider = (slider, open) => () => {
        setState({...state, [slider]: open});
    };

    const sideList = slider => (
        <Box component='div' className='menuSliderContainer' onClick={toggleSlider('right',false)}>
            <Avatar src={avatar} alt='Nhat Ho' className='avatar' />
            <List>
                {menuItems.map( (item, key) => (
                    <ListItem button key={key} component={Link} to={item.listPath}>
                        <ListItemIcon className={classes.listItem}>{item.listIcon}</ListItemIcon>
                        <ListItemText primary={item.listText} className={classes.listItem}/>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    /************************************************************************************/
    return (
        <Box component='nav' className={classes.homeContainer}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton onClick={toggleSlider('right',true)} className={classes.arrowback}>
                    <ArrowBack />
                    </IconButton>     
                    <Typography variant='h5' className={classes.headingNav}>Portfolio</Typography>
                    <MobilRightMenuSlider 
                            anchor='right'
                            open={state.right}
                            onClose={toggleSlider('right',false)}>
                            {sideList('right')}
                            <Footer/>
                    </MobilRightMenuSlider>

                    <Link to={'/'} style={{marginRight: '0.5rem'}}>
                        <Tooltip title='Home' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}> 
                            <HomeIcon fontSize='large' className={classes.homeicon}/>
                        </Tooltip>
                    </Link>
                    <Link to={'/aboutme'} >
                        <TadaDiv>
                        <Tooltip title='About Me'>   
                            <EmojiPeopleIcon fontSize='large' className={classes.peopleicon}/>
                        </Tooltip>
                        </TadaDiv>
                    </Link> 
  
                    <Link to={'/mycourses'} >
                        <Tooltip title='Courses'>   
                            <BadgeIcon fontSize='large' className={classes.mycoursesicon}/>
                        </Tooltip>
                    </Link> 

                    <Link to={'/projects'} > 
                        <Tooltip title='Projects' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                            <AssignmentIcon fontSize='large' className={classes.accounticon}/> 
                        </Tooltip>
                    </Link>
                        
                </Toolbar>
            </AppBar>
        </Box>
  );
}

export default Navbar;
