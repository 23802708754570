import React from 'react';
import {
    Box, Grid, Card, CardActionArea, CardContent, CardMedia, Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
// import Container from '@material-ui/core/Container';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Divider from '@material-ui/core/Divider';
/**********************************************************/
import { bounce} from 'react-animations';
import Radium, {StyleRoot} from 'radium';
// import color from 'color';
import styled, { keyframes } from "styled-components";
import Swing from "@bit/formidablelabs.react-animations.swing";
/**********************************************************/
import data from './data';
import backcplus from './images/backc++.jpg';
import backjava from './images/backjava.jpg';
import backjs from './images/backjs.jpg';
import backpython from './images/backpython.jpg';
import backreact from './images/backreact.jpg';
import backFirebaseReact from './images/backFirebaseReact.jpg';

const SwingAnimation = keyframes`${Swing}`;
const SwingDiv = styled.div`
  animation: 3s ${SwingAnimation};
`;

/**************Animation for StyleRoot******************/
const styles = {
    bounce: {
      animation: 'x 2s',
      animationName: Radium.keyframes(bounce, 'bounce')
      },
      bounceCard: {
        fontWeight: 'bolder',
        fontSize: '1rem !important',
        fontFamily: '\'Architects Daughter\', cursive',
        color: 'rgba(0, 0, 0, 0.9).lighten(0.2).hex()',
        transitionDuration: '.2s', 
        transitionTimingFunction: 'ease-out'
      }
}

const useStyles = makeStyles(theme => ({
    mainContainer: {
        //background: '#233',
        position: 'relative',
        height: '100%',
        width: '99%',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        //top: '50%',
        //left: '50%',
        //transform: 'translate(-50%, -50%)',
        //textAlign: 'center'
        [theme.breakpoints.up('md')]: {
            width: '100% !important'
        },
    },
    container: {
        padding: '5% 4% 0 4%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5%'
        }
    },
    cardContainer: {
        //maxWidth: 345,
        maxHeight: 220,
        minHeight: 220,
        marginTop: '2rem',
        borderRadius: '20px'
    },
    particle: {
        position: 'absolute',
        zIndex: 1,
        opacity: '0,2'
    }
}))

const image = [backcplus, backjava, backpython, backjs, backreact, backFirebaseReact];
const Projects = ({history, match}) => {
    // console.log(history);
    // console.log(match);
    const classes = useStyles();
    return (
        <div>
        <Box className={classes.mainContainer}>
            <Grid container className={classes.container} justify='center' alignItems='center' spacing={2}>
                {data.map( (cur, index) => 
                    (<Grid item xs={12} sm={2} md={4} key={index}>
                        <StyleRoot style={styles.bounce}>
                                <Link to={`${match.url}${cur.link}`}>
                                    <Card className={classes.cardContainer} >
                                        <SwingDiv>
                                            
                                                <CardActionArea>
                                                    <CardMedia component='img' alt={cur.name} height='140' image={image[`${index}`]}/>
                                                    <CardContent>
                                                        <Typography gutterBottom variant='h5'>
                                                            {cur.name}
                                                        </Typography>
                                                        <Typography variant='body2' color='textSecondary' component='p' style={styles.bounceCard}>
                                                            {cur.intro}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            
                                        </SwingDiv>
                                    </Card>
                                </Link>
                        </StyleRoot> 
                    </Grid>
                ))}
            </Grid>
        </Box>
        </div>
    );
}

export default Projects;