import cal1 from './images/sac/cal1.PNG';
import cal2 from './images/sac/cal2.PNG';
import cal3 from './images/sac/cal1.PNG';
import cal4 from './images/sac/cal4.PNG';
import C1 from './images/sac/C1.PNG';
import C2 from './images/sac/C2.PNG';
import C3 from './images/sac/C3.PNG';
import C4 from './images/sac/C4.PNG';
import java from './images/sac/java.PNG';
import EE1 from './images/sac/EE1.PNG';
import EE2 from './images/sac/EE2.PNG';
import EE3 from './images/sac/EE3.PNG';

import M51A from './images/ucla/M51A.PNG';
import EE31 from './images/ucla/EE3.PNG';
import EE100 from './images/ucla/EE100.PNG';
import EE102 from './images/ucla/EE102.PNG';
import MATH61 from './images/ucla/MATH61.PNG';
import STAT100A from './images/ucla/STAT100A.PNG';
import MATH134 from './images/ucla/MATH134.PNG';
import MATH135 from './images/ucla/MATH135.PNG';
import MATH170S from './images/ucla/MATH170S.PNG';
import M152 from './images/ucla/M152.PNG';
import M151B from './images/ucla/M151B.PNG';
import CS97 from './images/ucla/CS97.PNG';
import CS180 from './images/ucla/CS180.PNG';
import CS181 from './images/ucla/CS181.PNG';
import CS130 from './images/ucla/CS130.PNG';
import CS131 from './images/ucla/CS131.PNG';
import CS111 from './images/ucla/CS111.PNG';
import CS118 from './images/ucla/CS118.PNG';
import CS174A from './images/ucla/CS174A.PNG';
import CS161 from './images/ucla/CS161.PNG';
import CS143 from './images/ucla/CS143.PNG';
import CSM148 from './images/ucla/CSM148.PNG';
import CSM146 from './images/ucla/CSM146.PNG';

export const sac = [
    {
        name: 'CMPR120 - Introduction to Programming',
        source: C1,
        documentlink:'https://github.com/nhattanho/CMPR120',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CMPR121 - Programming Concepts',
        source: C2,
        documentlink:'https://github.com/nhattanho/CMPR121',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CMPR112 - Java Programming',
        source: java,
        documentlink:'https://github.com/nhattanho/CMPR112-Java',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CMPR129 - Introduction to Computer Organization',
        source: C3,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CMPR131 - Data Structure Concepts',
        source: C4,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'MATH180 - Single Variable Calculus I',
        source: cal1,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'MATH185 - Single Variable Calculus II',
        source: cal2,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'MATH280 - Intermediate Calculus',
        source: cal3,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    }, 
    {
        name: 'MATH287 - Introduction to Linear Algebra and Differential Equations​',
        source: cal4,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'PHYS217 - Engineering Physics I',
        source: EE1,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'PHYS227 - Engineering Physics II',
        source: EE2,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'PHYS237 - Engineering Physics III',
        source: EE3,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
];

export const ucla = [
    {
        name: 'CS M51A - Logic Design of Digital Systems',
        source: M51A,
        documentlink:'https://github.com/nhattanho/CS-M51A-Logic-Design-of-Digital-Systems',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS M152A - Introductory Digital Design Laboratory',
        source: M152,
        documentlink:'https://github.com/nhattanho/CS-M152A-Introductory-Digital-Design-Laboratory',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS M151B - Computer System Architectures',
        source: M151B,
        documentlink:'https://github.com/nhattanho/CS-M151B-Computer-System-Architectures',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 97 - Software Construction',
        source: CS97,
        documentlink:'https://github.com/nhattanho/BruinCarrer',
        statusdemo: true,
        linkdemo:'https://youtu.be/pPIcwWyfALQ',
    },
    {
        name: 'CS 130 - Software Engineering',
        source: CS130,
        documentlink:'https://github.com/nhattanho/CS-130-Software-Engineering',
        statusdemo: true,
        linkdemo:'https://www.youtube.com/watch?v=RAdHSv9Uqc8',
    },
    {
        name: 'CS 174A - Introduction to Computer Graphics',
        source: CS174A,
        documentlink:'https://github.com/nhattanho/CS-174A-Introduction-to-Computer-Graphics',
        statusdemo: true,
        linkdemo:'https://www.youtube.com/watch?v=gp3I9mn2uUU',
    },
    {
        name: 'CS 180 - Introduction to Algorithms and Complexity',
        source: CS180,
        documentlink:'https://github.com/nhattanho/CS-180-Introduction-to-Algorithms-and-Complexity',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 181 - Formal Languages and Automata',
        source: CS181,
        documentlink:'https://github.com/nhattanho/CS-181-Formal-Languages-and-Automata',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 131 - Programming Languages',
        source: CS131,
        documentlink:'https://github.com/nhattanho/CS-131-Programming-Languages',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 111 - Operating Systems Principles',
        source: CS111,
        documentlink:'https://github.com/nhattanho/CS-111-Operating-Systems-Principles',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 118 - Computer Network Fundamentals',
        source: CS118,
        documentlink:'https://github.com/nhattanho/CS-118-Computer-Network-Fundamentals-',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 161 - Fundamentals of Artificial Intelligence',
        source: CS161,
        documentlink:'https://github.com/nhattanho/CS-161-Fundamentals-of-Artificial-Intelligence',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS 143 - Data Management Systems',
        source: CS143,
        documentlink:'https://github.com/nhattanho/CS-143-Data-Management-Systems',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS M148 - Introduction to Data Science',
        source: CSM148,
        documentlink:'',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS M146 - Introduction to Machine Learning',
        source: CSM146,
        documentlink:'https://github.com/nhattanho/CS-M146-Introduction-to-Machine-Learning',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'CS/MATH61 - Discrete Mathematics',
        source: MATH61,
        documentlink:'https://github.com/nhattanho/CS-MATH61-Discrete-Mathematics',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'STATS 100A - Introduction to Probability',
        source: STAT100A,
        documentlink:'https://github.com/nhattanho/STATS-100A---Introduction-to-Probability',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'MATH 170S - Introduction to Probability and Statistics 2',
        source: MATH170S,
        documentlink:'https://github.com/nhattanho/MATH-170S-Introduction-to-Probability-and-Statistics-2',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'MATH 134 - Linear and Nonlinear Systems of Differential Equations.',
        source: MATH134,
        documentlink:'https://github.com/nhattanho/MATH-134---Linear-and-Nonlinear-Systems-of-Differential-Equations',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'MATH 135 - Ordinary Differential Equations',
        source: MATH135,
        documentlink:'https://github.com/nhattanho/MATH-135---Ordinary-Differential-Equations',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'EE3 - Introduction to Electrical Engineering',
        source: EE3,
        documentlink:'https://github.com/nhattanho/EC-ENGR-3',
        statusdemo: true,
        linkdemo:'https://youtu.be/sH-dEv3JldQ',
    },
    {
        name: 'EE100 - Electrical and Electronic Circuits',
        source: EE100,
        documentlink:'https://github.com/nhattanho/EC-ENGR-100-Electrical-and-Electronic-Circuits',
        statusdemo: false,
        linkdemo:'',
    },
    {
        name: 'EE102 - Systems and Signals',
        source: EE102,
        documentlink:'https://github.com/nhattanho/EC-ENGR-102-Systems-and-Signals',
        statusdemo: false,
        linkdemo:'',
    },
];
