 import React from 'react';
 import { Typography, Avatar, Grid, Box } from '@material-ui/core';
 import avatar from '../images/avatar.jpg';
 import Typed from 'react-typed';
 import {makeStyles} from '@material-ui/core';
 import {Link} from 'react-router-dom';
 import Tooltip from '@material-ui/core/Tooltip';

 const useStyles = makeStyles(theme=>(
     {
        avatar: {
            width: theme.spacing(25),
            height: theme.spacing(25),
            margin: theme.spacing(0),
            boxShadow: '0px 5px rgba(:0, :0, :0, :0.9)',
            [theme.breakpoints.down('sm')]: {
            marginBottom: '0.7rem'
            }
         },
         title: {
            color:'tomato',
            fontSize: '3rem',
            fontWeight: 200,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem'
            }
         },
         subtitle: {
            color: 'tan',
            marginBottom: '3rem',
            
         },
         typedContainer: {
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: '100vh',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]:{
                width: '100%'
                // marginTop: '40px'
            }
         }
     }
 ))

 const Header = () => {
     const classes = useStyles();
     return (
         <Box className={classes.typedContainer}>
            <Grid container justify='center'>
            <Link to='/signin'>
                <Tooltip title='SignIn'> 
                    <Avatar 
                    className={classes.avatar}
                    src={avatar} alt='Nhat Ho'>
                    </Avatar>
                </Tooltip>
            </Link>
            </Grid>
            
            <Typography 
            className={classes.title} variant='h4'>
                <Typed strings={['Welcome to my Portfolio']} typedSpeed={10} style={{fontFamily: '\'Architects Daughter\', cursive'}}/>
            </Typography>
            <br/>
            <Typography 
            className={classes.subtitle}
            variant='h5'>
                <Typed strings={['My name is Nhat Ho', 'Let\'s check my info!']} typedSpeed={5}
                backSpeed={60} style={{fontFamily: '\'Architects Daughter\', cursive'}}
                loop/>
            </Typography>
         </Box>
     )
 }

 export default Header;