import {ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import {Typography, Button, Grid, Box} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';
import Confetti from 'react-confetti'
import Tooltip from '@material-ui/core/Tooltip';
import {useStyles, InputField} from './style_contact';
import Validate from "../../validation/validate";
import { useHistory } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react"
import emailjs, { init } from "@emailjs/browser";
/*https://github.com/ChisomUma/EmailJs-tutorial/blob/main/src/components/ContactForm.js*/
const serverID = 'portfolio';
const templateID = 'template_fu79ggj';
const userID = "user_kejqDTteIQfYR06IrfIiW";

const Contacts = () => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name:'',
        message: '',
        email:'',
        company:'',
    });
    const [errors, setErrors] = React.useState({});
    const [status, setStatus] = React.useState(false);
    const history = useHistory();
    init(userID);

    const handleChange = (e) => {
        setValues(values => ({
          ...values,
          [e.target.name]: e.target.value
        }))
      }

    const onSubmit = (e) => {
        e.preventDefault();
        /*console.log(password);*/
        const contactObject = {
          email: values.email,
          name: values.name,
          message: values.message,
          company: values.company,
        };
        let result = Validate({...contactObject});
        console.log(result);
        setErrors(result);
        if(!result.pass) return;
        //console.log("email " + contactObject.email);
        emailjs.send(serverID, templateID, contactObject, userID)
        .then((result) => {
            console.log(result.text);
            setValues({
                name: '',
                email: '',
                company: '',
                message: '',
              });
            setStatus(true);
        }, (error) => {
            console.log(error.text);
        });
    }

    useEffect(() => {
        if(status) {
          setTimeout(() => {
            setStatus(false);
          }, 10000);
        }
      }, [status]);

    return (
            <Box component='div' className={classes.container}>
                <Grid container justify='center' style={{zIndex: 2}}>
                    <Box component='form' className={classes.form}>
                        {status && <div className={classes.sentmessage} style={{zIndex: 3}}> 
                            <p>Your message submitted successfully!</p>  
                        </div>}
                        <div className={classes.info}>
                            <ListItem className={classes.item}>
                                <ListItemIcon className={classes.heading}> <PhoneIphoneIcon/> </ListItemIcon>
                                <ListItemText primary='(657)-238-7889'/>
                            </ListItem>
                            <ListItem className={classes.item}>
                                <ListItemIcon className={classes.heading}> <EmailIcon/> </ListItemIcon>
                                <ListItemText primary='davisho2411@gmail.com'/>
                            </ListItem>
                        </div>
                        <Typography variant='h5' style={{color: 'tomato', textAlign: 'center', textTransform: 'uppercase'}}>
                           contact me
                        </Typography>
                        <InputField 
                            className={classes.input}
                            fullWidth={true}
                            label='Name'
                            variant='outlined'
                            margin='dense'
                            size='medium'
                            inputProps={{ style:{color: 'white'}  }}
                            onChange={handleChange}
                            error={!!errors.name}
                            helperText={errors.name ? errors.name : ""}
                            value={values.name}
                            name='name'
                        />
                        <br/>
                        <InputField 
                            className={classes.input}
                            fullWidth={true}
                            label='Email'
                            variant='outlined'
                            margin='dense'
                            size='medium'
                            inputProps={{ style:{color: 'white'}  }}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email ? errors.email : ""}
                            value={values.email}
                            name="email"
                        />
                        <br/>
                        <InputField
                            className={classes.input}
                            fullWidth={true}
                            label='Company'
                            variant='outlined'
                            margin='dense'
                            size='medium'
                            inputProps={{ style:{color: 'white'}  }}
                            onChange={handleChange}
                            error={!!errors.company}
                            helperText={errors.company ? errors.company : ""}
                            value={values.company}
                            name="company"
                        />
                        <br/>
                        <InputField
                            className={classes.input}
                            fullWidth={true}
                            label='Message'
                            variant='outlined'
                            margin='dense'
                            size='medium'
                            type='string'
                            multiline={true}
                            inputProps={{ style:{color: 'white', height: '80px'} }}
                            onChange={handleChange}
                            error={!!errors.message}
                            helperText={errors.message ? errors.message : ""}
                            value={values.message}
                            name="message"
                        />
                        <br/>

                        <Tooltip title='Thanks!'>
                            <Button onClick={(e) => onSubmit(e)} variant='outlined' fullWidth={true} endIcon={<SendIcon/>} className={classes.button}>
                                Contact Me
                            </Button>
                        </Tooltip>
                    </Box>
                </Grid>
                {!status && <Confetti opacity={0.5} style={{zIndex: 0, width: '100%', height: '100%'}}/>}
            </Box>
    );
}

export default Contacts;