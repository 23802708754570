import React from 'react'; 
import { AssignmentInd, Home, Apps, ContactMail} from '@material-ui/icons'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BadgeIcon from '@mui/icons-material/Badge';

const menuItems = [
    {
        listIcon: <Home />,
        listText: 'Home',
        listPath: '/'
    },
    {
        listIcon: <EmojiPeopleIcon/>,
        listText: 'AboutMe',
        listPath: '/aboutme'
    },
    {
        listIcon: <AssignmentInd />,
        listText: 'Resume',
        listPath: '/resume'
    },
    {
        listIcon: <Apps />,
        listText: 'Projects',
        listPath: '/projects'
    },
    {
        listIcon: <BadgeIcon />,
        listText: 'Courses',
        listPath: '/mycourses'
    },
    {
        listIcon: <ContactMail />,
        listText: 'Contact',
        listPath: '/contacts'
    }
];

export default menuItems;

