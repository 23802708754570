import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: '#233',
        fontFamily: '\'Architects Daughter\', cursive',
        width: '99%'
    },
    heading: {
        color: 'tomato',
        padding: '5rem 0 1rem 0',
        textTransform: 'uppercase',
        fontFamily: '\'Architects Daughter\', cursive',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px'
        }
    },
    timeLine: {
        position: "relative",
        padding: "1rem",
        margin: "0 auto",
        "&:before": {
            content: "''",
            position: "absolute",
            height: "100%",
            border: "1px solid tan",
            right: "20px",
            top: 0
        },
        "&:after": {
            content: "''",
            display: "table",
            clear: "both"
        },
        [theme.breakpoints.up("md")]:{
            padding: "2rem",
            "&:before": {
                left: "calc(50% - 1px)",
                right: "auto"
            }
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            // marginRight: '-2rem'
        }
    },
    timeLineItem: {
        padding: "1rem",
        borderBottom: "2px solid tan",
        postion: "relative",
        margin: "1rem 3rem 1rem 1rem",
        clear: "both",
        fontFamily: '\'Architects Daughter\', cursive',
        "&:after": {
            content: "''",
            position: "absolute"
        },
        "&:before": {
            content: "''",
            position: "absolute",
            right: "-0.625rem",
            top: "calc(50% - 5px)",
            borderStyle: "solid",
            borderColor: "tomato tomato transparent transparent",
            borderWidth: "0.625rem",
            transform: "rotate(45deg)"
        },
        [theme.breakpoints.up("md")]:{
            width: "44%",
            margin: "1rem",
            "&:nth-of-type(2n)":{
                float: "right",
                margin: "1rem",
                borderColor: "tomato"
            },
            "&:nth-of-type(2n):before": {
                right: "auto",
                left: "-0.625rem",
                borderColor: "transparent transparent tomato tomato"
            }
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            left: '-2.3rem !important',
            position: 'relative',
            width: '91%'
        }
    },
    timeLineYear: {
        zIndex: 2,
        position: 'relative',
        textAlign: "center",
        maxWidth: "18rem",
        margin: "0 3rem 40px auto ",
        //marginLeft: "700px !important",
        fontSize: "1.8rem",
        color: "white",
        background: "tomato",
        lineHeight: 1,
        padding: "0.5rem 0 1rem",
        "&:before": {
            display: "none",
            // position: 'absolute',
            // marginRight: "530px !important",
            // top: "calc(50% - 5px)"
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "center",
            margin: "0 auto",
            "&:nth-of-type(2n)": {
                float: "none",
                margin: "0 auto"
            },
            "&:nth-of-type(2n):before": {
                display: "none"
            }
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
            marginLeft: '2rem'
        }
    },
    subHeading: {
        color: 'white',
        padding: '0',
        textTransform: 'uppercase'
    },
    root: {
        '& > *': {
          margin: theme.spacing(1),
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5]
        
        // padding: theme.spacing(2, 4, 3),
    },
    image: {
        [theme.breakpoints.down('sm')]: {
            height: '350px !important',
            width: '350px !important'
        },
        [theme.breakpoints.down('sm')]: {
            height: '280px !important',
            width: '280px !important'
        }
    },
    resume: {
        color: 'tomato',
        textTransform: 'uppercase',
        fontFamily: '\'Architects Daughter\', cursive',
        left: "45%",
        position: "relative",
        fontSize: "28px",
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px'
        }
    },
    startheading: {
        fontFamily: '\'Architects Daughter\', cursive',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px'
        }
    },
    volunteer: {
        backgroundImage: 'linear-gradient(to right bottom, #FBDB89, #F48982)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        textAlign: 'center',
        // padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover':{
            opacity: '1.4'
        },
        [theme.breakpoints.down('sm')]: {
            height: '320px ',
            width: '320px'
        }
    },
}));
