import React from 'react';
import { Card, makeStyles } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

export default function ShowVolunteer(props) {
    const { backgroundImage } = props.content;

    const useStyles = makeStyles((theme) => ({
        card: {
            borderRadius: 5,
            width: '500px',
            // boxShadow: '20px 20px 20px white',
            boxShadow: '0px 0px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
                height: '300px ',
                width: '300px'
            }
        },
    }));

    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardMedia
                component='img'
                className={classes.media}
                image={backgroundImage}
            />
        </Card>
    );
}