import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {Typography, Box} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Dialog from '@material-ui/core/Dialog';
import avatar from './aboutme.jpg';
import Typed from 'react-typed';
import background from './background.jpg';
import ShowVolunteer from './showVolunteer';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core';

import vl1 from './volunteer1.jpg';
import vl2 from './volunteer2.jpg';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'tomato',
        position: 'relative',
        top: '4rem',
        fontFamily: "'Playfair Display', serif !important",
        backgroundImage: `url(${background})`,
        // background: 'linear-gradient(to top, #5b525270, #62555533)',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '40px ',
        [theme.breakpoints.down('sm')]: {
            top: '3rem'
        }
    },
    heading: {
        alignItems: 'center',
        position: 'relative',
        display: 'flex',
        width: '80%',
        flexDirection: 'row',
        textShadow: '0 2px 5px black',
        background: 'linear-gradient(to top, #5b525270, #62555533)',
        border: '2px solid black',
        borderRadius: '20px',
        boxShadow: 'inset 0 20px 4px -19px rgba(255, 255, 255, 0.4), 0 12px 12px 0 rgba(0, 0, 0, 0.3)',
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
             width: '90%'
        }

    },
    avatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        }
    },
    introavatar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginLeft: '1rem',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    name: {
        fontSize: 'xx-large',
        color: 'wheat',
        [theme.breakpoints.down('sm')]: {
            fontSize: 'small',
            marginLeft: '-1.1rem'
        }
    },
    intro: {
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 'small'
        }
        // fontFamily: "'Playfair Display', serif !important"
    },
    headtag: {
        color: 'tan',
        marginTop: '1rem',
        fontSize: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 'bolder',
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
            width: '15rem'
        }
    },
    contentbox: {
        color: '#e3aa5e',
        marginTop: '30px',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
             width: '100%'
        }
    },
    content: {
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px'
        }
    },
    svg: {
        height: '30px',
        cursor: 'pointer' 
    },
    volunteer: {
        backgroundImage: 'linear-gradient(to right bottom, #FBDB89, #F48982)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        textAlign: 'center',
        // padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover':{
            opacity: '1.4'
        },
        [theme.breakpoints.down('sm')]: {
            height: '320px ',
            width: '320px'
        }
    }
}))

const AboutMe = () => {
    const classes = useStyles();
    const [openVolunteer, setOpenVolunteer] = React.useState(false);
    const album = [vl1, vl2];
    const handleClick = () => {
        setOpenVolunteer(!openVolunteer);
    };

    const handleClose = () => {
        setOpenVolunteer(false);
    }
    /*****************************************************************************/
    const [slideIn, setSlideIn] = React.useState(true);
    const [slideDirection, setSlideDirection] = React.useState('down');

    const [index, setIndex] = React.useState(0);
    const content = album[index];
    const numSlides = album.length;

    function Arrow(props) {
        const { direction, clickFunction } = props;
        const icon = direction === 'left' ? <FaChevronLeft /> : <FaChevronRight />;
        return <div className={classes.svg} onClick={clickFunction}>{icon}</div>;
    }
    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (index + increment + numSlides) % numSlides;
        
        const oppDirection = direction === 'left' ? 'right' : 'left';
        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            setIndex(newIndex);
            setSlideDirection(oppDirection);
            setSlideIn(true);
        }, 500);
        // setIndex(newIndex);
    };
    /*****************************************************************************/
    return (
        <Box className={classes.container}>
            <Box className={classes.heading}>
                <Avatar alt='Nhat Ho' src={avatar} className={classes.avatar}/>
                <div className={classes.introavatar}>
                    <div className={classes.name}>Nhat Ho</div>
                    <Typography className={classes.intro}>
                        Nhat Ho used to be a software engineering in Viet Nam. He is an avid learner and has a
                        strong passion for programming. Now, he graduated student at UCLA with a computer science major.<br/>
                    </Typography>
                </div>
            </Box>

            <div className={classes.headtag}>
                <Typed strings={['Hi Guys, Thank you so much for coming to my portfolio!']} typedSpeed={5}
                backSpeed={60} style={{fontFamily: '\'Architects Daughter\', cursive'}}
                />
                <Typed strings={['Let\'s me talk about my story!']} typedSpeed={10} style={{fontFamily: '\'Architects Daughter\', cursive'}}/>
            </div>

            <Box className={classes.contentbox}>
                <Typography className={classes.content}>
                    As a science enthusiast as a child, I began to know about programming while I was in
                    high school. Having many years of learning programming before coming to the US fueled
                    my passion for continuing the IT path, so I decided to stay diving into school to improve
                    more knowledge about computer science at Santa Ana College, US.<br/><br/>
                </Typography>
                <Typography className={classes.content}>
                    Becoming a math and computer science tutor not only a chance for me to help and inspire
                    fellow to continue keeping their passion but also provided me with great opportunities to
                    communicate with them that improved my speaking skills.<br/><br/>
                </Typography>
                <Typography className={classes.content}>
                    In addition to my academic efforts, actively participating in volunteer activities with more
                    than <Link onClick={handleClick}>100 hours</Link> of contributions helped me get the approval of 8 universities in the United
                    States. And UCLA was where I decided to study and continue my passion.<br/><br/>
                </Typography>
                <Dialog
                    open={openVolunteer}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className={classes.volunteer}>
                        <Arrow
                            direction='left'
                            clickFunction={() => onArrowClick('left')}
                        />   
                        <Slide in={slideIn} direction={slideDirection}>
                            <div>
                                <ShowVolunteer content={{backgroundImage: content}}></ShowVolunteer>
                            </div>
                        </Slide>
                        <Arrow
                            direction='right'
                            clickFunction={() => onArrowClick('right')}
                        />
                    </div>
                </Dialog>
            </Box>
        </Box>
    )
}

export default AboutMe;