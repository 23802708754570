import grad1 from './grad/grad1.PNG';
import grad2 from './grad/grad2.PNG';
import grad3 from './grad/grad3.PNG';
import grad4 from './grad/grad4.jpg';
import grad5 from './grad/grad5.jpg';
import grad6 from './grad/grad6.jpg';
import grad7 from './grad/grad7.jpg';
import grad8 from './grad/grad8.jpg';
import grad9 from './grad/grad9.jpg';
import grad10 from './grad/grad10.jpg';

export  {
    grad1, grad2, grad3, grad4, grad5, grad6, grad7, grad8, grad9, grad10,
};

export const linkResume = "https://firebasestorage.googleapis.com/v0/b/portfolio-5765e.appspot.com/o/resume%2FDavisHo_Resume.pdf?alt=media&token=7b766b5a-3387-404e-970f-9b2e46da325f";
export const linkJamar = 'https://jmars.asu.edu/';
export const linkHackathon = 'https://devpost.com/software/iprofile';
export const linkVideo = 'https://firebasestorage.googleapis.com/v0/b/portfolio-5765e.appspot.com/o/graduate%2Fgrad11.mp4?alt=media&token=1496c20e-761f-469a-925c-3815ba9724c2';
